import React, { useEffect } from "react";
import { generateGuid } from "./kendo_common";
import $ from "jquery";
export const KendoGrid = ({
  name,
  url,
  data,
  schema,
  columns,
  serverPaging,
  serverFiltering,
  serverSorting,
  filterable,
  sortable,
  paggable,
  scrollable,
  height,
  disableToolbar,
  exportFileName,
  showOptions,
  width,
  groupable,
  onEditClick,
  onDeleteClick,
  showEdit,
  showDelete,
  onDataBound,
  refreshWhen,
  disableExportExcel,
  optionWidth,
}) => {
  var guId = generateGuid();
  useEffect(() => {
    //kenod grid options
    if (showOptions || false) {
      var Id = columns[0].field;
      var options = {
        template: function (e) {
          let edit =
            showEdit == false
              ? ""
              : `<a href="javascript:void(0);" id="k-grd-${name}-edit-${e[Id]}" title="Edit"><i class="k-icon k-i-pencil k-grid-edit m-2" ></i></a>`;
          let del =
            showDelete == false
              ? ""
              : `<a href="javascript:void(0);" id="k-grd-${name}-delete-${e[Id]}" title="Delete"><i class="k-icon k-i-trash k-grid-delete m-2"></i></a>`;
          return `<div>
            ${edit} ${del}
            </div>`;
        },
        title: "Option",
        width: optionWidth || "100px",
      };

      if (columns.find((e) => e.title == "Option") == null) {
        columns.push(options);
      }
    }

    let toolBar = [
      {
        name: "excel",
        template: `
    <button class="k-button k-grid-excel btn-excel">
      <span class="k-icon k-i-file-excel"></span>
    </button>
  `,
      },
      "search",
    ];
    if (disableExportExcel) {
      toolBar.splice(0, 1);
    }

    $(`#${guId}-${name}`).kendoGrid({
      dataSource: {
        transport: url && {
          read: url,
        },
        data: !url && (data || []),
        schema: {
          model: {
            fields: schema,
          },
        },
        pageSize: 10,
        serverPaging: serverPaging || false,
        serverFiltering: serverFiltering || false,
        serverSorting: serverSorting || false,
      },
      width: width || "auto",
      scrollable: scrollable || true,
      filterable: filterable || false,
      sortable: sortable || true,
      toolbar: !disableToolbar && toolBar,
      excel: {
        fileName: exportFileName,
        proxyURL: "https://demos.telerik.com/kendo-ui/service/export",
        filterable: true,
      },
      pageable: !(paggable == false) && {
        pageSizes: [5, 10, 50, 100],
        buttonCount: 5,
      },
      columns: columns,
      dataBound: function (e) {
        if (showOptions) {
          var grid = this;
          grid.dataSource.data().forEach(function (dataItem) {
            $(`#k-grd-${name}-edit-${dataItem[Id]}`).on("click", function () {
              onEditClick(dataItem);
            });

            $(`#k-grd-${name}-delete-${dataItem[Id]}`).on("click", function () {
              onDeleteClick(dataItem);
            });
          });
        }
        if (onDataBound) {
          onDataBound();
        }
        if (scrollable || true) {
        }
      },
    });
  }, [data, refreshWhen]);

  return <div id={`${guId}-${name}`}></div>;
};
