import React, { useEffect, useState } from "react";
import BreadcrumbHeader from "../Common/BreadcrumbHeader";
import Footer from "../Common/Footer";
import Loader from "../Common/Loader";
import WithoutFooter from "../Common/WithoutFooter";
import Scroll from "../Common/Scroll";
const DigitalTransformation = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      $(".preloader").css("display", "none");
    }, 500);
  }, []);
  return (
    <>
      <Loader />
      <BreadcrumbHeader />
      <section className="topMargin">
        <div className="why-chhose-us-area ">
          <div className="container ">
            <div className="row ">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12 wow fadeInUp" data-wow-delay=".2s">
                    <div className="single-about">
                      <div className="section-title">
                        <h2>Digital Transformation</h2>
                      </div>
                      {/* <p>
                  Unlocking digital potential through tailored solutions, data
                  optimization, and modernization strategies
                </p> */}
                      <div className="single-about">
                        <h4 style={{ marginTop: "50px" }}>
                          Embarking on a Digital Transformation Journey{" "}
                        </h4>
                        <p>
                          "We're transforming into a new era. All of our
                          technology must be stacked appropriately.
                          Communication, business process, ownership and
                          accountability are crucial as we strive to deliver on
                          specific business outcomes."{" "}
                        </p>
                      </div>
                      <div className="single-about">
                        {/* <h4>Comprehensive Application Management</h4> */}
                        <p>
                          {" "}
                          PEN Software accelerates digital transformation and
                          business growth by integrating essential capabilities
                          for thriving in the digital age. We empower clients to
                          leverage data and artificial intelligence, modernize
                          core technology, optimize and automate operations,
                          fuel digital growth, create stunning user experiences,
                          and build digital talent and culture.{" "}
                        </p>
                      </div>
                      <div className="single-about">
                        <h4>
                          All of this is possible because of the exceptional
                          team we've put together.{" "}
                        </h4>
                        <p>
                          Our diverse team includes data scientists, engineers,
                          architects, developers, technology leads, agile
                          coaches, designers, and cybersecurity experts, all
                          working flexibly and seamlessly to tackle challenges
                          and deliver solutions.
                        </p>
                        {/* <p class="aligned-ORACLE-CONSULTING-text">
                              • Service requests <br>
                              • Incident management <br>
                              • Routine tasks <br>
                              • Feature enhancements <br>
                              We employ a well-defined method with custom-developed processes, 
                              policies, procedures, standards, and templates. Unlike the traditional
                               model, which focuses on labor hours, our model is based on outcomes. Our 
                               engineers are compensated based on client satisfaction, providing better service 
                               to support your business without the scope creep or endless change requests common
                                with traditional vendor engagement.
                            </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="call-to-action-area"
        data-stellar-background-ratio="0.6"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12 ">
              <div className="section-sub-title text-center">
                <h2>What's your goal today? </h2>
              </div>
              <p className="aligned-text">
                • Harness the power of data and analytics <br />
                • Build a digital culture <br />
                • Modernize core technology <br />
                • Create stunning digital experiences <br />
                • Accelerate marketing &amp; sales <br />
                • Optimize your operations <br />
              </p>
              <p className="text-center">
                {" "}
                Let's transform your business together. Contact us today to get
                started!
              </p>
              <div className="text-center">
                <a
                  className="slide-btn smoth-scroll "
                  style={{ marginTop: "20px" }}
                  href="/Connectwithus"
                >
                  Contact Us
                </a>
              </div>

              {/* <a href="#" class="read-more white-read-more">Purchase now</a> */}
            </div>
          </div>
        </div>
      </section>

      <WithoutFooter />
      <Scroll />
    </>
  );
};
export default DigitalTransformation;
