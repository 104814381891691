import AxiosServices from "./AxiosServices";

const Auth = new AxiosServices();
class ContactServices 
{
  Create = async (formData) => {
    debugger
    return await Auth.post(`Contact/Create`, formData);
  };
  GetContactList = async () => {
    debugger
    return Auth.get(`Contact/ContactList`);
  };
}

export default ContactServices;