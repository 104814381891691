import React, { useEffect } from "react";
const Scroll = () => {
  useEffect(() => {
    const scrollToTopButton = document.getElementById("scrollToTopButton");

    const handleScroll = () => {
      // Show the button when scrolled down 300px
      if (window.scrollY > 300) {
        scrollToTopButton.style.display = "block";
      } else {
        scrollToTopButton.style.display = "none";
      }
    };

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    // Add scroll event listener to show/hide the button
    window.addEventListener("scroll", handleScroll);

    // Add click event listener to scroll to the top
    scrollToTopButton.addEventListener("click", scrollToTop);

    // Cleanup function to remove event listeners when component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
      scrollToTopButton.removeEventListener("click", scrollToTop);
    };
  }, []);
  return (
    <>
      <div className="scroll-to-up">
        <div className="scrollup" id="scrollToTopButton">
          <span
            className="lnr lnr-chevron-up"
            style={{ marginTop: "15px", marginLeft: "5px" }}
          />
        </div>
      </div>
    </>
  );
};
export default Scroll;
