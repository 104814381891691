import React from "react";
import AxiosServices from "./AxiosServices";

const Auth = new AxiosServices();

class RecruitmentServices {
  Create = async (data) => {
    debugger
    return Auth.post(`Recruitment/SaveRecruitmentDetails`, data);
  };
  UploadResume = async (uploadresume) => {
    
    return Auth.post(`Recruitment/ResumeUpload`, uploadresume, {
      "content-type": "multipart/form-data"
    });
  };
  Download = async (url,filename) => {
    return Auth.get(`Recruitment/Download?url=${url}&filename=${filename}`,{responseType : 'blob'});
  };

  GetRecuritmentAdmin = async () => {
    return Auth.get('/Recruitment/Recruitment_GetList');
  };
}

export default RecruitmentServices;