import React from "react"
const Loader = () => {
    
    return(
        <>
        <div className="preloader" id="preloader">
            <div className="status">
                <div className="status-mes" />
            </div>
        </div>
        </>
    )

}
export default Loader
