import React, { useEffect, useState } from "react";
import BreadcrumbHeader from "../Common/BreadcrumbHeader";
import WithoutFooter from "../Common/WithoutFooter";
import Loader from "../Common/Loader";
import Scroll from "../Common/Scroll";
const OracleConsulting = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      $(".preloader").css("display", "none");
    }, 1000);
  }, []);
  return (
    <>
      <Loader />
      <BreadcrumbHeader />
      <>
        <section className="topMargin">
          <div className="why-chhose-us-area ">
            <div className="container ">
              {/* <div className="col-md-4">
            <div className="why-image">
              <img src="/assets/images/noimg.png" alt="" />
            </div>
          </div> */}
              <div className="row">
                <div className="col-md-12  wow fadeInUp" data-wow-delay=".2s">
                  <div className="single-about">
                    <div className="section-title">
                      <h2> Oracle Consulting</h2>
                    </div>
                    <p className="justify">
                      PEN Software was founded to disrupt and redefine the
                      enterprise software support model by developing innovative
                      methods and services that prioritize business outcome and
                      client success. We deliver premium support and highly
                      skilled engineering expertise for Oracle products at a
                      fraction of the cost.
                    </p>
                    <div className="single-about">
                      <h4>Personalized Support</h4>
                      <p className="justify">
                        At the core of our service model is personalized
                        support, offering direct access to expert engineers to
                        ensure a superior support experience. Our Application
                        Management Services (AMS) cover service requests,
                        incidents, routine tasks, and feature enhancements,
                        ensuring efficient management throughout the application
                        lifecycle.
                      </p>
                    </div>
                    <div className="single-about">
                      <h4>Comprehensive Application Management</h4>
                      <p className="justify">
                        {" "}
                        Application Management is crucial for managing
                        applications throughout their lifecycles. It supports
                        and maintains operational applications and plays a key
                        role in designing, testing, and improving applications
                        that are part of IT services.
                      </p>
                    </div>
                    <div className="single-about">
                      <h4>Value-Driven Application Management Services</h4>
                      <p> Our AMS approach includes:</p>
                      <p className="aligned-ORACLE-CONSULTING-text justify">
                        • Service requests <br />
                        • Incident management <br />
                        • Routine tasks <br />
                        • Feature enhancements <br />
                        We employ a well-defined method with custom-developed
                        processes, policies, procedures, standards, and
                        templates. Unlike the traditional model, which focuses
                        on labor hours, our model is based on outcomes. Our
                        engineers are compensated based on client satisfaction,
                        providing better service to support your business
                        without the scope creep or endless change requests
                        common with traditional vendor engagement.
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  className="col-md-12 col-sm-6 col-xs-12 wow fadeInUp"
                  data-wow-delay=".6s"
                ></div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="call-to-action-area"
          data-stellar-background-ratio="0.6"
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12 ">
                <div className="section-sub-title text-center">
                  <h2>Oracle E-Business Suite Product Lines Supported</h2>
                </div>
                <p className="aligned-text">
                  • Financial Management <br />
                  • Customer Relationship Management <br />
                  • Procurement <br />
                  • Project Portfolio Management <br />
                  • Human Capital Management <br />
                  • Service Management <br />
                  • Oracle Customer Care and Billing System
                  <br />
                </p>
                <p className="text-center">
                  Ready to optimize your Oracle solutions? Contact us today to
                  learn more and get started!
                </p>
                <div className="text-center">
                  <a className="slide-btn smoth-scroll " href="/Connectwithus">
                    Contact Us
                  </a>
                </div>
                {/* <a href="#" class="read-more white-read-more">Purchase now</a> */}
              </div>
            </div>
          </div>
        </section>
      </>

      <WithoutFooter />
      <Scroll />
    </>
  );
};
export default OracleConsulting;
