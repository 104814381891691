import React, { useEffect, useState } from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import Scroll from "../Common/Scroll";
import Loader from "../Common/Loader";
import HomeBody from "./HomeBody";
const Home = () => {
  const [list, setlist] = useState([]);
  const [isLoaded, setIsLoaded] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      $(".preloader").css("display", "none");
    }, 1000);
  }, []);

  return (
    <>
      <Loader />
      <Header />
      <HomeBody />
      <Footer />
      <Scroll />
    </>
  );
};
export default Home;
