import "./KendoScripts/styles/kendo.common.min.css";
import "./KendoScripts/styles/kendo.material.min.css";
import "./KendoScripts/js/kendo.all.min.js";
import "./Components/StyleVT/styleVt.css";
import Home from "../src/Components/Home/Home"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Ouruniverse from "../src/Components/OurUniverse/OurUniverse";
import DigitalTransformation from "../src/Components/Expertise/DigitalTransformation"
import OracleConsulting from "../src/Components/Expertise/OracleConsulting"
import SAPConsulting from "../src/Components/Expertise/SAPConsulting"
import ProfessionalStaffing from "../src/Components/Expertise/ProfessionalStaffing"
import Excellence from "../src/Components/Excellance/Excellence"
import PenTalk from "./Components/PENTalk/PENTalk";
import PenTalkDetails from "./Components/PENTalk/PENTalkDetails";
import Oppourtunities from "./Components/Oppourtunities/Oppourtunities";
import Connectwithus from "./Components/Connectwithus/Connectwithus";
// import LoginView from "./Components/Admin/Login/LoginView";
import Dashboard from "./Components/Admin/Dashboard/Dashboard";
import ContactList from "./Components/Admin/Contact/ContactList";
import ForgotView from "./Components/Admin/ForgotPassWord/ForgotPassWord";
import OtpView from "./Components/Admin/ForgotPassWord/CheckOtp";
import ConfirmPassword from "./Components/Admin/ForgotPassWord/ChangePassword";
import PENTalkDetailsCategoryTwo from "./Components/PENTalk/PENTalkDetailsCategoryTwo.js";
// import CareerListTable from "./Components/Admin/Career/CareerListTable";


function App() {
  return (
   
       <Router>
       <Routes>
       {/* <Route path="/Admin" element={<LoginView />}></Route> */}
       <Route path="/ForgotView" element={<ForgotView />} />
       <Route path="/OtpView" element={<OtpView />} />
       <Route path="/ChangePassword" element={<ConfirmPassword />} />
       <Route path="/ContactList" element={<ContactList />}></Route>
       <Route path="/DashboardView" element={<Dashboard />}></Route>
       <Route exact path="/" element={<Home />}></Route>
       <Route exact path="/OurUniverse" element={<Ouruniverse/>}></Route>
       <Route exact path="/DigitalTransformation" element={<DigitalTransformation/>}></Route>
       <Route exact path="/OracleConsulting" element={<OracleConsulting/>}></Route>
       <Route exact path="/SAPConsulting" element={<SAPConsulting/>}></Route>
       <Route exact path="/ProfessionalStaffing" element={<ProfessionalStaffing/>}></Route>
       <Route exact path="/Excellence" element={<Excellence/>}></Route>
       <Route exact path="/PenTalk" element={<PenTalk/>}></Route>
       <Route exact path="/PenTalkDetails" element={<PenTalkDetails/>}></Route>
       <Route exact path="/PENTalkDetailsCategoryTwo" element={<PENTalkDetailsCategoryTwo/>}></Route>
       <Route exact path="/Oppourtunities" element={<Oppourtunities/>}></Route>
       <Route exact path="/Connectwithus" element={<Connectwithus/>}></Route>
       {/* <Route path="/AdminCareer" element={<CareerListTable />}></Route> */}
       </Routes>
       </Router>
    
  );
}

export default App;
