import React ,{useEffect} from "react";

export default function HeaderDashboard() {
  function logout() {
    // localStorage.removeItem("token");
    // cookies.remove("emailID");
    // cookies.remove("userID");
    // cookies.remove("userName");
    // cookies.remove("token");
    window.location.href = "/Admin";
  }
  const DropdownView = (val) => {

    var ClassVal = "." + val;
    $(ClassVal).css("display", "block");
  };
  const DropdownLeave = (val) => {

    var ClassVal = "." + val;
    $(ClassVal).css("display", "none");
  };
  useEffect(() => {
    import('../../StyleVT/styleVt.css');
    import('../../../VTcss/bootsnav.css')
    // import('../../../VTcss/animate.css')
    // import('../../../VTcss/owl.theme.default.min.css')
    // import('../../../VTcss/owl.carousel.min.css')
    // import('../../../VTcss/magnific-popup.css')
    // import('../../../VTcss/elegant-icons.css')
    // import('../../../VTcss/themify-icons.css')
    // import('../../../VTcss/responsive.css')
    import('../../../VTJs/bootsnav.js')
  }, [])
  return (
    <>
      <header id="home" className="dashboard">
       
        <nav className="navbar navbar-default navbar-sticky bootsnav">
          <div className="container-full">
            {/* Start Header Navigation */}
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle"
                data-toggle="collapse"
                data-target="#navbar-menu"
              >
                <i className="fa fa-bars" />
              </button>
              <a className="navbar-brand" href="/DashboardView">
                <img src="/assets/images/favicon.png" className="logo" alt="Logo" />
              </a>
            </div>
           
            <div
              className="collapse navbar-collapse"
              id="navbar-menu"
              style={{ display: "block" }}
            >
              <ul
                className="nav navbar-nav navbar-center navdirection"
                data-in="#"
                data-out="#"
              >
                <li>
                  <a href="/Dashboardview">Home</a>
                </li>
                <li
                  className="dropdown"
                  onMouseEnter={() => DropdownView("ddm")}
                  ontouchstart={() => DropdownView("ddm")}
                  onMouseLeave={() => DropdownLeave("ddm")}
                >
                  <a
                    href="#"
                    className="dropdown-toggle active"
                    data-toggle="dropdown"
                  >
                    Company
                  </a>
                  <ul className="dropdown-menu ddm">
                    <li>
                      <a href="/HomeBanner">Home Banners</a>
                    </li>
                    <li>
                      <a href="/AdminCareer">Careers</a>
                    </li>
                    <li>
                      <a href="/ContactListTable">Inquiries</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="/Projects">Products</a>
                </li>
                <li
                  className="dropdown"
                  onMouseEnter={() => DropdownView("ddc")}
                  ontouchstart={() => DropdownView("ddc")}
                  onMouseLeave={() => DropdownLeave("ddc")}
                >
                  <a
                    href="#"
                    className="dropdown-toggle active"
                    data-toggle="dropdown"
                  >
                    Clients
                  </a>
                  <ul className="dropdown-menu ddc">
                    <li>
                      <a href="/clients">Clients</a>
                    </li>
                    <li>
                      <a href="/Testimonial">Testimonials</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="/Events">Events</a>
                </li>
                <li
                  className="dropdown"
                  onMouseEnter={() => DropdownView("ddp")}
                  ontouchstart={() => DropdownView("ddp")}
                  onMouseLeave={() => DropdownLeave("ddp")}
                >
                  <a
                    href="#"
                    className="dropdown-toggle active"
                    data-toggle="dropdown"
                  >
                    Privacy & Terms
                  </a>
                  <ul className="dropdown-menu ddp">
                    <li>
                      <a href="/AdminPrivacy">Privacy & Policy</a>
                    </li>
                    <li>
                      <a href="/AdminTerms"> Terms & Conditions</a>
                    </li>
                    <li>
                      <a href="/AdminFaq">FAQ </a>
                    </li>
                  </ul>
                </li>
                <li
                  className="dropdown profiledd"
                  onMouseEnter={() => DropdownView("ddl")}
                  ontouchstart={() => DropdownView("ddl")}
                  onMouseLeave={() => DropdownLeave("ddl")}
                >
                  <a href="#" className="active" data-toggle="dropdown">
                    {" "}
                    <div className="profilename">
                      <i className="fa fa-user" />
                      &nbsp;
                      <label
                        className="usernameicon"
                        // title={cookies.get("userName")}
                      >
                        {/* {cookies.get("userName")} */}
                      </label>
                    </div>
                  </a>
                  <ul className="dropdown-menu ddl">
                    <li>
                      <a href="/ChangePassword">
                        <i class="fa fa-key" aria-hidden="true"></i> Change
                        Password
                      </a>
                    </li>
                    <li>
                      <a onClick={() => logout()}>
                        <i class="fa fa-sign-out"></i> Log out
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            {/* /.navbar-collapse */}
          </div>
        </nav>
        {/* End Navigation */}
      </header>
      {/* End Header */}
    </>
  );
}
