import React, { useContext, useState, useEffect } from "react";
// import "../../../../node_modules/bootstrap/dist/css/bootstrap.css";
// import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
// import "../../../Assets/css/style.css";
// import vaanam from "../../../Assets/img/Vaanam.png";
// import loginImage from "../../../Assets/img/login.svg";
import LoginService from "../../../Services/LoginService";
import { useForm } from "react-hook-form";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { AuthContext } from "../../../Context/AuthContext";
// import "./Login.css";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import $ from "jquery";
import Loader from "../../Common/Loader";

const LoginView = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const passwordToggle = () => {
    setPasswordToggle(!passwordtoggle);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    setTimeout(() => {
      $("#preloader").hide();
    }, 1000);

  });
  
  useEffect(() => {
    import('../../StyleVT/styleVt.css');
    import ('../Login/Login.css')
   
  }, [])

  const [disabled, setDisabled] = useState(false);

  const [dummy, setDummy] = useState({
    prefixName: "",
    endValue: "",

    errUserName: "",
    errPassword: "",
  });
  const { data, setCookie, removeCookie } = useContext(AuthContext);
  const [passwordtoggle, setPasswordToggle] = useState(true);
  const AuthObject = new LoginService();
  const cookies = new Cookies();
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + 15);
  const navigate = useNavigate();

  const onKeyDown = (event) => {
    if (event.code === "Space") event.preventDefault();
  };
  const validateUser = (e) => {
    var myerror = dummy;
    if (e.target.value == null || e.target.value == "") {
      myerror.errUserName = "Username is Required";
    } else {
      myerror.errUserName = "";
    }
    setDummy({ ...myerror });
  };
  const validatePassword = (e) => {
    var myerror = dummy;
    if (e.target.value == null || e.target.value == "") {
      myerror.errPassword = "Password is Required";
    } else {
      myerror.errPassword = "";
    }
    setDummy({ ...myerror });
  };
  const loginSubmit = (e) => {
    e.preventDefault();
    var myerror = dummy;

    var flag = true;
    const obj = new FormData(e.currentTarget);
    var data = {
      UserName: e.currentTarget.UserName.value,
      Password: e.currentTarget.Password.value,

      rememberMeflag: e.currentTarget.Remember.value,
    };
    if (data.UserName == null || data.UserName == "") {
      myerror.errUserName = "Username is Required";
      flag = false;
    } else {
      myerror.errUserName = "";
    }
    if (data.Password == null || data.Password == "") {
      myerror.errPassword = "Password is Required";
      flag = false;
    } else {
      myerror.errPassword = "";
    }
    setDummy({ ...myerror });

    if (flag == true) {
      login(data);
    }
  };
  const login = (authentication) => {

    if (authentication.rememberMeflag == true) {
      cookies.set("KAPPU", authentication.UserName, { path: "/" });
      cookies.set("KAPPP", authentication.Password, { path: "/" });
    }
    setIsLoaded(true);
    // $(".main-loader").show();
    AuthObject.getData(authentication)

      .then(
        (res) => (
          
          cookies.set("emailID", res.data[0].officeMailID, {
            path: "/",
            expires: expirationDate,
          }),
          cookies.set("userID", res.data[0].userID, {
            path: "/",
            expires: expirationDate,
          }),
          cookies.set("userName", res.data[0].userName, {
            path: "/",
            expires: expirationDate,
          }),
          cookies.set("token", res.data[0].token, {
            path: "/",
            expires: expirationDate,
          }),
          localStorage.setItem("token", res.data[0].token),
          setTimeout(() => {
            RedirectFunction();
          }, 2000),
          setDisabled(false)
          // setTimeout(() => { Toast('Logged in Succesfully', 'success')}, 500)
        )
      )

      .catch((res) => {
        setIsLoaded(false);
        swal({
          text: "Please Enter valid User Name or Password",
          icon: "warning",
        });

        cookies.remove("KAPPU");
        cookies.remove("KAPPP");
        setDisabled(false);
      });

    function RedirectFunction() {
      setIsLoaded(false);
      window.location.href = "/Dashboard";
      ////$('.main-loader').hide();
    }
  };

  return (
    <>
      <Loader />
      <div className="login-area bg-gray" style={{ height: "713px" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <form className="white-popup-block" onSubmit={loginSubmit}>
                <div className="row align-center">
                  <div className="col-lg-5 login-social">
                    <img src="/assets/images/Vaanam.png" alt="Logo" style={{ height: "60px" }} />{" "}
                    <br></br>
                    <img
                      src="/assets/images/login.svg"
                      alt="Login"
                      style={{ height: "263px" }}
                    />
                  </div>
                  <div className="col-md-7 login-custom">
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="form-group">
                          <label>
                            <h6>Enter Username</h6>
                          </label>
                          <input
                            type="text"
                            name="UserName"
                            maxLength={50}
                            className="form-control"
                            placeholder="Username"
                            // {...register("UserName", {
                            //   required: "UserName is Required",
                            // })}
                            value={register.UserName}
                            onChange={(e) => validateUser(e)}
                          // onChange={() => setError("UserName", "")}
                          />{" "}
                          {/* {errors.UserName && (
                            <span className="text-danger">
                              {errors.UserName.message}
                            </span>
                          )} */}
                          <span className="text-danger">
                            {dummy.errUserName}
                          </span>
                        </div>
                        {/* <p className="error" style={{ color: "red" }}> */}
                        {/* {formErrors.username}</p> */}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="row">
                        <div className="form-group">
                          <label>
                            <h6>Enter Password</h6>
                          </label>
                          <input
                            type={passwordtoggle ? "password" : "text"}
                            className="form-control"
                            placeholder="Password"
                            maxLength={50}
                            name="Password"
                            // {...register("Password", {
                            //   required: "Password is Required",
                            // })}
                            value={register.Password}
                            onKeyDown={(event) => onKeyDown(event)}
                            onChange={(e) => validatePassword(e)}
                          // onChange={() => validate(2)}
                          // onChange={() => clearErrors("Password")}
                          // onChange={() => setError("Password", "")}
                          />
                          {/* {errors.Password && (
                            <span className="text-danger">
                              {errors.Password.message}
                            </span>
                          )} */}
                          <span className="text-danger">
                            {dummy.errPassword}
                          </span>
                          <span className="password-icon">
                            <span onClick={() => passwordToggle()}>
                              {passwordtoggle ? <FaEyeSlash /> : <FaEye />}
                            </span>
                          </span>
                        </div>
                        <p className="error" style={{ color: "red" }}>
                          {/* {formErrors.password} */}
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="row">
                        <label htmlFor="login-remember">
                          <input
                            type="checkbox"
                            id="Remember"
                            name="Remember"
                            value={register.Remember}
                          />
                          Remember Me
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="row">
                        {!isLoaded ? (
                          <button type="submit">Login</button>
                        ) : (
                          <button
                            className="btn btn-primary"
                            type="button"
                            disabled
                          >
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Loading...
                          </button>
                        )}
                      </div>
                     </div>
                     <div>
                      <a
                        title="Lost your password"
                        href="/ForgotView"
                        className="lost-pass-link"
                      >
                        Lost your password?
                      </a>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginView;
