import React from "react";

const WithoutFooter = () => {
  return (
    <>
      <footer
        className="footer-area wow fadeInUp"
        data-wow-delay="0s"
        style={{
          visibility: "visible",
          animationDelay: "0s",
          animationName: "fadeInUp",
        }}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-sm-12 text-center section-footer-padding"
              style={{ marginTop: "0px" }}
            >
              <div className="footer-social-link">
                <ul>
                  {/* <li>
              <a href="">
                <i className="fa fa-facebook" />
              </a>
            </li>
            <li>
              <a href="">
                <i className="fa fa-twitter" />
              </a>
            </li>
            <li>
              <a href="">
                <i className="fa fa-dribbble" />
              </a>
            </li>
            <li>
              <a href="">
                <i className="fa fa-google-plus" />
              </a>
            </li> */}
                  <li>
                    <a
                      href="https://www.linkedin.com/company/pensoftwareinc"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-linkedin-square fa-lg" />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="footer-text">
                <h6>©All Copyright 2024 by PEN Software</h6>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default WithoutFooter;
