import React, { useEffect, useState } from "react";
import HeaderDashboard from "../Dashboard/HeaderDashboard";
import ContactServices from "../../../Services/ContactServices";

// import MUIDataTable from "mui-datatables";
import LoginView from "../Login/LoginView";
import Cookies from "universal-cookie";
import { KendoGrid } from "../../../Kendo/kendo_grid";

var ContactServicesObj = new ContactServices();
const ContactList = () => {
  const cookies = new Cookies();
  const [Auth, SetAuth] = useState();
  const [ContactDetails, setContactDetails] = useState([]);
  useEffect(() => {
    import("../../StyleVT/styleVt.css");
    import('../../../VTcss/bootsnav.css')
    // import('../../../VTcss/bootstrap.min.css')
  }, []);
  const [Model2, setModel2] = useState({
    name: '',
    email: '',
    phone: '',
    companyName: '',
    helptext:'',
    messages:'',

  });

  useEffect(() => {
    SetAuth(cookies.get("userID"));
    ContactServicesObj.GetContactList().then((res) => {
      if (res.data != null && res.data.length > 0) {
        console.log(res.data);
        setContactDetails([...res.data]);
      }
      setTimeout(() => {
        $(".Loader").hide();
      }, 1000);
    });
  }, []);
  const renderEyeIconCell = (props) => {
    return (
      <td>
        <i className="fa fa-eye" onClick={() => handleEyeClick(props.dataItem)}></i>
      </td>
    );
  };
  return Auth ? (
    <>
      <HeaderDashboard />
      <div className="card">
  <div className="card-header">Role List</div>
  <div className="container mt-5">
    <div className="card-body">
      <KendoGrid
        name={"ContactLists"}
        data={ContactDetails}
        columns={[
          {
            field: "contactID",
            title: "Contact ID",
            hidden: true,
          },
          {
            field: "name",
            title: "Name",
          },
          {
            field: "phone",
            title: "Mobile Number",
          },
          {
            field: "email",
            title: "Email",
          },
          {
            field: "companyName",
            title: "Company Name",
          },
          {
            title: "View",
            cell: (props) => {
                console.log("Rendering cell for:", props.dataItem);
                return (
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    data-toggle="modal"
                    data-target="#addRowModal"
                    onClick={() => setModel2({ ...props.dataItem })}
                  >
                    View
                  </button>
                );
              },
          },
        ]}
        disableExportExcel={false}
        optionWidth={"100px"}
      />
    </div>
  </div>
</div>

<div class="card-body">
                      <div
                        class="modal fade"
                        id="addRowModal"
                        tabindex="-1"
                        role="dialog"
                        aria-hidden="true"
                        data-bs-backdrop="static"
                        data-backdrop="static"
                      >
                        {/* <ViewInquiry Model={Model2} /> */}
                      </div>
                    </div>
    </>
  ) : (
    <LoginView />
  );
};
export default ContactList;
