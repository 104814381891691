import Axios from "axios";

// DEV
// export const baseUrl = "http://localhost:47189/api/"; 

// QA
// export const baseUrl= "https://qaapi.pensoftware.io/api/";

// AZURE QA
//export const baseUrl="https://pensoftwareqaapi.azurewebsites.net/api/";

// Production
export const baseUrl="https://api.pensoftware.io/api/";
Axios.defaults.baseURL = baseUrl;

Axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("token");

export default class AxiosServices {
  post(url, data, Header = false) {
    return Axios.post(url, data, Header);
  }
  get(url, header = null) {
    return Axios.get(url, header);
  }
}
