import React, { useEffect, useState } from "react";
import BreadcrumbHeader from "../Common/BreadcrumbHeader";
import Footer from "../Common/Footer";
import Loader from "../Common/Loader";
import Scroll from "../Common/Scroll";
import WithoutFooter from "../Common/WithoutFooter";
const ProfessionalStaffing = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      $(".preloader").css("display", "none");
    }, 500);
  }, []);

  const options = {
    loop: true,
    margin: 10,
    nav: false,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
      0: { items: 2 },
      576: { items: 3 },
      768: { items: 4 },
      992: { items: 5 },
      1200: { items: 6 },
    },
  };
  return (
    <>
      <Loader />
      <BreadcrumbHeader />
      {/* <section
        id="service"
        className="service-area section-padding"
      >
        <div className="container section-padding">
          <div className="row">
            <div className="col-sm-12">
              <div className="section-title ">
                <h2>Professional Staffing </h2>
              </div>
            </div>
          </div>
          <div className="row">
          
            <div
              className="col-md-6 col-sm-6 wow fadeInUp"
              data-wow-delay=".2s"
            >
              <div className="single-service-Expertise ">
                <span className="lnr lnr-laptop text-center" />
                <h4 className="text-center">
                  “MY PEN” – Your Professional Staffing Experience
                </h4>
                <p>
                  The Made to Fit experience tailored to your evolving IT
                  staffing needs and changing requirements - We call it “Su
                  Misura”
                </p>
                <p>
                  PEN Software excels in professionalism and creativity,
                  offering unmatched global service across diverse technology
                  areas, supported by over 20 years of IT and staffing
                  expertise.
                </p>
              </div>
            </div>
           
            <div
              className="col-md-6 col-sm-6 wow fadeInUp"
              data-wow-delay=".4s"
            >
              <div className="single-service-Expertise ">
                <span className="lnr lnr-laptop text-center" />
                <h4 className="text-center">Cherry-Pick your Hires </h4>
                <p>
                  Every placement in your organization is meticulously crafted
                  around your needs and culture. We customize the search,
                  screening, matching, and selection process to find the perfect
                  candidate for your organization
                  <br />
                  All these unique choices are decided during a one-to-one
                  meeting with our professional recruiter, who will guide you
                  through the experience.
                </p>
              </div>
            </div>
           
            <div
              className="col-md-6 col-sm-6 wow fadeInUp"
              data-wow-delay=".6s"
            >
              <div className="single-service-Expertise ">
                <span className="lnr lnr-keyboard text-center" />
                <h4 className="text-center">The Perfect Match</h4>
                <p>
                  Our team, deeply rooted in the IT industry, brings firsthand
                  experience and expertise to each recruitment endeavor. We
                  excel in finding the perfect match for your technical roles.
                </p>
              </div>
            </div>
           
            <div
              className="col-md-6 col-sm-6 wow fadeInUp"
              data-wow-delay=".8s"
            >
              <div className="single-service-Expertise ">
                <span className="lnr lnr-earth text-center" />
                <h4 className="text-center">Web of Tech Contacts</h4>
                <p>
                  With extensive connections in the technology industry, our
                  team taps into a vast network of top talent. We focus on core
                  skill sets in markets where we have deep connections, ensuring
                  quality matches with people we know and trust.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-sm-6 wow fadeInUp"
              data-wow-delay=".8s"
            >
              <div className="single-service-Expertise ">
                <span className="lnr lnr-earth text-center" />
                <h4 className="text-center">One-on-One Relationship</h4>
                <p>
                  We prioritize the human element – building relationships. Your
                  dedicated recruiter will personally guide you through every
                  step of the search process.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="topMargin">
        <div className="why-chhose-us-area ">
          <div className="container ">
            <div class="row">
              <div className="single-about">
                <div class="col-sm-12">
                  <div class="section-title ">
                    <h2> Professional Staffing </h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-5">
                <div className="why-image">
                  <img
                    className="PENImgResize"
                    src="assets/images/Professional Staffing_Su Misura Image (1).jpeg"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-7">
                <div className="row">
                  <div className="col-md-12 wow fadeInUp" data-wow-delay=".2s">
                    <div className="single-about">
                      <h4>“MY PEN” – Your Professional Staffing Experience</h4>
                      <p className="justify">
                        The Made to Fit experience tailored to your evolving IT
                        staffing needs and changing requirements - We call it
                        “Su Misura” <br></br>
                        PEN Software excels in professionalism and creativity,
                        offering unmatched global service across diverse
                        technology areas, supported by over 20 years of IT and
                        staffing expertise.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="single-about">
                <h4>Cherry-Pick your Hires </h4>
                <p className="justify">
                  Every placement in your organization is meticulously crafted
                  around your needs and culture. We customize the search,
                  screening, matching, and selection process to find the perfect
                  candidate for your organization <br></br>
                  All these unique choices are decided during a one-to-one
                  meeting with our professional recruiter, who will guide you
                  through the experience.
                </p>
              </div>

              <div className="single-about">
                <h4>The Perfect Match </h4>

                <p className="justify">
                  Our team, deeply rooted in the IT industry, brings firsthand
                  experience and expertise to each recruitment endeavor. We
                  excel in finding the perfect match for your technical roles.
                </p>
              </div>
              <div className="single-about">
                <h4>Web of Tech Contacts </h4>
                <p className="justify">
                  With extensive connections in the technology industry, our
                  team taps into a vast network of top talent. We focus on core
                  skill sets in markets where we have deep connections, ensuring
                  quality matches with people we know and trust.
                </p>
              </div>
              <div className="single-about">
                <h4>One-on-One Relationship </h4>
                <p className="justify">
                  We prioritize the human element – building relationships. Your
                  dedicated recruiter will personally guide you through every
                  step of the search process.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section
        id="testimonial"
        className="testimonial-area section-padding"
        data-stellar-background-ratio="0.6"
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="section-title white-title">
                <h2>Multiple Ways to Hire</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="testimonial-list wow fadeInUp">
           
                <div className="single-testimonial text-center">
                  <h6 className="text-muted">
                    Contract &amp; Contract-to-Hire.
                  </h6>

                  <h6>On-demand, flexible support for technical positions </h6>
                </div>
                <div className="single-testimonial text-center">
                  <h6 className="text-muted">
                    Statement-of-Work (SOW) / Project Based.{" "}
                  </h6>

                  <h6>Committed project-based teams with defined timelines</h6>
                </div>
                <div className="single-testimonial text-center">
                  <h6 className="text-muted">Direct Hire</h6>

                  <h6>Find the right FTE for long term needs</h6>
                </div>
           
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="section-title white-title">
                <p className="text-center">
                  Ready to find the perfect fit for your team? Contact us today
                  to discuss your staffing needs and get started!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
       */}
      <section id="service" className="service-area topMargin">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="section-title">
                <h2>MULTIPLE WAYS TO HIRE</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col-md-4 col-sm-6 wow fadeInUp"
              data-wow-delay=".2s"
            >
              <div className="single-service-Expertise text-center">
                <span className="lnr lnr-camera" />
                <h4>Contract & Contract-to-Hire. </h4>
                <p>On-demand, flexible support for technical positions</p>
              </div>
            </div>

            <div
              className="col-md-4 col-sm-6 wow fadeInUp"
              data-wow-delay=".4s"
            >
              <div className="single-service-Expertise text-center">
                <span className="lnr lnr-laptop" />
                <h4>Statement-of-Work (SOW) / Project Based. </h4>
                <p>Committed project-based teams with defined timelines</p>
              </div>
            </div>

            <div
              className="col-md-4 col-sm-6 wow fadeInUp"
              data-wow-delay=".6s"
            >
              <div className="single-service-Expertise text-center">
                <span className="lnr lnr-keyboard" />
                <h4>Direct Hire</h4>
                <p>Find the right FTE for long term needs</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="call-to-action-area team-area "
        data-stellar-background-ratio="0.6"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12 ">
              <p className="text-center">
                Ready to find the perfect fit for your team? Contact us today to
                discuss your staffing needs and get started!
              </p>

              <div className="text-center">
                <a
                  className="slide-btn smoth-scroll "
                  style={{ marginTop: "20px" }}
                  href="/Connectwithus"
                >
                  Contact Us
                </a>
              </div>
              {/* <a href="#" class="read-more white-read-more">Purchase now</a> */}
            </div>
          </div>
        </div>
      </section>

      <WithoutFooter />
      <Scroll />
    </>
  );
};
export default ProfessionalStaffing;
