import React, { useEffect, useState } from "react";
import BreadcrumbHeader from "../Common/BreadcrumbHeader";
import Footer from "../Common/Footer";
import ExcellenceBody from "./ExcellenceBody";
import Loader from "../Common/Loader";
import Scroll from "../Common/Scroll";

const Excellance = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      $(".preloader").css("display", "none");
    }, 500);
  }, []);
  return (
    <>
      <Loader />
      <BreadcrumbHeader />
      <ExcellenceBody />
      <Footer />
      <Scroll />
    </>
  );
};
export default Excellance;
