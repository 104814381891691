import React, { useEffect, useState } from "react";
import BreadcrumbHeader from "../Common/BreadcrumbHeader";
import Footer from "../Common/Footer";
import Loader from "../Common/Loader";
import WithoutFooter from "../Common/WithoutFooter";
import Scroll from "../Common/Scroll";
const SAPConsulting = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      $(".preloader").css("display", "none");
    }, 500);
  }, []);
  return (
    <>
      <Loader />
      <BreadcrumbHeader />

      <section className="topMargin">
        <div className="why-chhose-us-area ">
          <div className="container">
            <div className="row ">
              {/* <div className="col-md-4">
            <div className="why-image">
              <img src="/assets/images/noimg.png" alt="" />
            </div>
          </div> */}
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12  wow fadeInUp" data-wow-delay=".2s">
                    <div className="single-about">
                      <div className="section-title">
                        <h2>SAP Consulting</h2>
                      </div>
                      <p className="justify">
                        At PEN Software, we harness innovation to tackle complex
                        challenges and implement transformative business
                        solutions using SAP technologies. Our commitment to
                        continuous improvement ensures timely delivery, enabling
                        clients to achieve comprehensive visibility and
                        strategic alignment.
                      </p>
                      <div className="single-about">
                        <h4>SAP Application Development</h4>
                        <p className="justify">
                          Organizations often require tailored applications to
                          meet specific business needs. PEN Software delivers
                          exceptional value through cloud-based custom
                          application development, maximizing profitability and
                          ensuring anytime, anywhere access. Our Rapid Iterative
                          Methodology, encompassing research, design,
                          development, and usability testing, ensures efficient
                          delivery.
                        </p>
                      </div>
                      <div className="single-about">
                        <h4>SAP Integration Services</h4>
                        <p className="justify">
                          {" "}
                          We offer comprehensive integration services to
                          streamline business processes across internal and
                          external systems, supporting diverse deployment
                          models.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="call-to-action-area"
        data-stellar-background-ratio="0.6"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12 ">
              <div className="section-sub-title text-center">
                <h2> SAP Application Implementation</h2>
              </div>
              <p className="aligned-text justify">
                Our services cover: <br />
                • Product Roadmap and strategy projection <br />
                • Business Process Mapping <br />
                • Interfaces and Conversion <br />
                • Application Configuration <br />
                • Training and Support <br />
              </p>
              <p className="text-center">
                {" "}
                Ready to optimize your SAP solutions? Contact us today to learn
                more and get started!
              </p>
              <div className="text-center">
                <a
                  className="slide-btn smoth-scroll "
                  style={{ marginTop: "20px" }}
                  href="/Connectwithus"
                >
                  Contact Us
                </a>
              </div>
              {/* <a href="#" class="read-more white-read-more">Purchase now</a> */}
            </div>
          </div>
        </div>
      </section>

      <WithoutFooter />
      <Scroll />
    </>
  );
};
export default SAPConsulting;
