import React from "react";

const Footer = () => (
  <>
    <footer
      className="footer-area wow fadeInUp"
      data-wow-delay="0s"
      style={{
        visibility: "visible",
        animationDelay: "0s",
        animationName: "fadeInUp",
        marginTop: "20px",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-sm-4">
            <div className="plan-title">
              <div className="email-container">
                <i className="fa fa-envelope" />
                <h5>Email:</h5>
                <p>
                  <a href="mailto:hr@pensoftware.io" className="email-link">
                    hr@pensoftware.io
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-4  ">
            <div className="plan-title">
              <div className="email-container">
                <i className="fa fa-phone" />
                <h5>Phone :</h5>
                <p>469 964 2008</p>
              </div>
            </div>
          </div>
          <div className="col-sm-4 ">
            <div className="plan-title">
              <div className="email-container">
                <i class="fa fa-map-marker"></i>

                <h5>Address :</h5>
              </div>
              <p>
                5605 North MacArthur Blvd, 10th Floor <b />
                Suite 1034 Irving, TX, 75038
              </p>
            </div>
          </div>
          <div className="col-sm-12 text-center section-footer-padding">
            <div className="footer-social-link">
              <ul>
                {/* <li>
                  <a href="">
                    <i className="fa fa-facebook" />
                  </a>
                </li>
                <li>
                  <a href="">
                    <i className="fa fa-twitter" />
                  </a>
                </li>
                <li>
                  <a href="">
                    <i className="fa fa-dribbble" />
                  </a>
                </li>
                <li>
                  <a href="">
                    <i className="fa fa-google-plus" />
                  </a>
                </li> */}
                <li>
                  <a
                    href="https://www.linkedin.com/company/pensoftwareinc"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-linkedin-square fa-lg" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer-text">
              <h6>©All Copyright 2024 by PEN Software</h6>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </>
);
export default Footer;
