import React from 'react';
import PropTypes from 'prop-types';

const JobDetailsModal = () => {
  return (
    <div>
        <div className="job-title">
                        Oracle Financials Techno-Functional Consultant
                      </div>
                      <div className="job-location">San Jose, CA, USA</div>
                      <div className="job-type">Contract</div>
      <p>
        <b>Position:</b> Oracle Financials Techno-Functional Consultant
      </p>
      <p>
        <b>Work Location:</b> San Jose, CA, USA
      </p>
      <p>
        <b>Work Place Type:</b> Onsite
      </p>
      <p>
        <b>Employment Type:</b> Contract
      </p>
      <p>
        <b>Contract Term:</b> 12 months (+ Further Extension possible)
      </p>
      <p>
        <b>Summary:</b>
        <span>
          As an Oracle Financials Techno-Functional Consultant, you will be responsible for Oracle Financials EBS projects for PEN Software’s clients on a contract basis.
        </span>
      </p>
      <p>
        <b>Indicative Experience:</b> 10 years + in Oracle EBS
      </p>
      <p>
        <b>Required Skills for the position:</b>
      </p>
      <p className="Oppo-skills">
        • Techno Functional experience supporting Procure to Pay, Cash Management (Bank Reconciliations, Bank Integrations knowledge is a plus), Project Billing, Service Contracts.
      </p>
      <p className="Oppo-skills">
        • Hands On Technical knowledge on Oracle Forms, Reports, Workflow, Interfaces, Integrations and added advantage if have knowledge on Tableau.
      </p>
      <p className="Oppo-skills">
        • Good Communication and interpersonal skills to engage with the cross-functional team members for project and support assignments.
      </p>
      <p className="Oppo-skills">
        • Should be able to communicate with the business for driving business requirements, Demo, User Acceptance testing, Support, etc.
      </p>
    </div>
  );
};

JobDetailsModal.propTypes = {
  job: PropTypes.shape({
    position: PropTypes.string,
    location: PropTypes.string,
    workPlaceType: PropTypes.string,
    employmentType: PropTypes.string,
    contractTerm: PropTypes.string,
    summary: PropTypes.string,
    experience: PropTypes.string,
    skills: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default JobDetailsModal;
